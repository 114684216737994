import Stepper, { StepContext } from '@UIKit/Stepper/Stepper';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import TopBar from '@/components/TopBar/TopBar';
import { smoothScroll } from '@/helpers/smooth-scroll';
import FeedbackDetail from './FeedbackDetail/FeedbackDetail';
import FeedbackGlobal from './FeedbackGlobal/FeedbackGlobal';
import { useParams } from 'react-router-dom';
import { useFeedback } from '@/hooks/useFeedbacks';
import {
  DetailedFeedbackModel,
  ImprovementAxesModel,
  UserActionModel
} from '@/models/DetailedFeedbackModel';

const Feedback = () => {
  const { exerciseSessionID } = useParams();
  const { setActiveStepId } = useContext(StepContext);
  const { unlockedTrophyID, detailedFeedbacks, statsFeedbacks, isLoading, errorMessage } =
    useFeedback(exerciseSessionID);

  const [hasPageBeenRendered, setHasPageBeenRendered]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [isDetailSectionDisplayed, setIsDetailSectionDisplayed]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const detailSectionRef = useRef<HTMLDivElement>(null);

  let scrollY = 0;

  const computeImprovementAxes = (detailedFeedbacks: DetailedFeedbackModel[]) => {
    const improvementAxes: ImprovementAxesModel[] = [];

    detailedFeedbacks.forEach((detailedFeedback) => {
      detailedFeedback.userActions?.forEach((userAction: UserActionModel) => {
        if (
          userAction.evaluation === 'BAD' ||
          userAction.evaluation === 'FAIL' ||
          userAction.isMissedOpportunity
        ) {
          improvementAxes.push({
            index: userAction.id,
            kind: 'WARNING',
            label: <>{userAction.displayedName}</>
          });
        }
      });
    });

    return improvementAxes.slice(0, 3);
  };

  const onWheel = (event: WheelEvent) => {
    if (
      !isDetailSectionDisplayed &&
      event.deltaY > 0 &&
      window.scrollY + window.innerHeight >= document.body.scrollHeight - 1
    ) {
      setIsDetailSectionDisplayed(true);
    }
  };

  const onScroll = () => {
    if (!detailSectionRef.current) {
      return;
    }

    if (hasPageBeenRendered && isDetailSectionDisplayed) {
      const newScrollY = window.scrollY;
      const isScrollingUp = scrollY - newScrollY > 0;

      if (
        isScrollingUp &&
        window.scrollY + window.innerHeight <
          detailSectionRef.current.offsetTop + detailSectionRef.current.offsetHeight + 1
      ) {
        setIsDetailSectionDisplayed(false);
      }

      scrollY = newScrollY;
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', onWheel);
    window.addEventListener('scroll', onScroll);

    setHasPageBeenRendered(true);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('wheel', onWheel);
    };
  });

  useEffect(() => {
    if (hasPageBeenRendered && isDetailSectionDisplayed) {
      scrollToDetail();
    }
  }, [isDetailSectionDisplayed]);

  useEffect(() => {
    if (detailedFeedbacks) {
      setActiveStepId(detailedFeedbacks[0].id || '');
    }
  }, [detailedFeedbacks]);

  const onClickOnShowDetailLink = () => {
    setIsDetailSectionDisplayed(true);

    if (hasPageBeenRendered && isDetailSectionDisplayed) {
      scrollToDetail();
    }

    if (detailedFeedbacks) {
      setActiveStepId(detailedFeedbacks[0].id || '');
    }
  };

  const scrollToDetail = () => {
    if (!detailSectionRef.current) {
      return;
    }

    smoothScroll(detailSectionRef.current, 500);
  };

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="spinner-loader dark mx-auto block"></div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex h-screen items-center justify-center text-red-500">{errorMessage}</div>
    );
  }

  if (!unlockedTrophyID || !detailedFeedbacks) {
    return (
      <div className="flex h-screen items-center justify-center">
        {isLoading ? 'Loading feedback data...' : 'No feedback data available.'}
      </div>
    );
  }

  return (
    <>
      <div className="flex min-h-[calc(100vh-theme(spacing.28))] flex-col">
        <TopBar className="w-full" />

        <div className="centered-wide-row mb-1 flex flex-1 flex-col pt-8">
          <FeedbackGlobal
            unlockedTrophyID={unlockedTrophyID}
            statsFeedbacks={statsFeedbacks}
            improvementAxes={computeImprovementAxes(detailedFeedbacks)}
            className="sm:px-6"
            onClickOnShowDetailLink={onClickOnShowDetailLink}
          />
        </div>
      </div>

      <div ref={detailSectionRef} className="centered-wide-row w-full py-8 sm:!px-12">
        <Stepper
          detailedFeedbacks={detailedFeedbacks}
          callback={onClickOnShowDetailLink}
          isTabMode={isDetailSectionDisplayed}
        />
      </div>

      <div
        id="detail"
        className={`${
          !isDetailSectionDisplayed
            ? 'hidden'
            : 'not-important-opacity-0 animate-fadeIn [animation-delay:.3s]'
        } centered-wide-row flex min-h-[calc(100vh-theme(spacing.28))] flex-col pb-8`}>
        <FeedbackDetail
          detailedFeedbacks={detailedFeedbacks}
          unlockedTrophyID={unlockedTrophyID}
          className="mb-8 mt-auto sm:px-6"
        />
        <svg
          width="126"
          height="96"
          viewBox="0 0 126 96"
          className="mx-auto mt-auto h-auto w-16 fill-brand"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false">
          <use href={`${defs}#watermark`} />
        </svg>
      </div>
    </>
  );
};

export default Feedback;
