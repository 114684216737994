import { EvaluationPicto } from '@UIKit/EvaluationPicto/EvaluationPicto';
import Modal from '@UIKit/Modal/Modal';
import React, { Dispatch, SetStateAction, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { Translation } from '@/components/Utilities/Translation';
import { TrophyIdType, TrophyModel } from '@/models/TrophyModel';
import BentoLayout from './BentoLayout';
import { TROPHIES } from './trophies';
import { StatsFeedbackModel } from '@/models/StatsFeedbackModel';
import { ImprovementAxesModel } from '@/models/DetailedFeedbackModel';

// for now these values are set to null, the related block are disabled
const DIFF_APPROACH_USER_RATE = null;
const TROPHY_GLOBAL_USER_RATE = null;

const TrophyStatsRow = ({
  diffApproachUserRate = 0,
  positiveInteractionRate = 0
}: {
  diffApproachUserRate?: number | null;
  positiveInteractionRate?: number;
}) => (
  <BentoLayout.RowOfCards>
    {diffApproachUserRate && (
      <BentoLayout.HalfRowCard
        className="not-important-opacity-0 flex animate-fadeIn flex-col justify-between [animation-delay:.1s]"
        theme="BRAND">
        <header className="flex w-full">
          <h2 className="flex-1 label">
            Pourcentage d’apprenants ayant employé une autre&nbsp;approche
          </h2>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            className="ml-4 h-8 w-auto"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false">
            <use href={`${defs}#pie-chart`} />
          </svg>
        </header>
        <p className="mb-4 figure">{diffApproachUserRate}%</p>
      </BentoLayout.HalfRowCard>
    )}
    <BentoLayout.HalfRowCard
      style={
        {
          '--rate': `${positiveInteractionRate}%`,
          overflow: 'hidden'
        } as React.CSSProperties
      }
      className="not-important-opacity-0 relative flex animate-fadeIn flex-col justify-center text-center [animation-delay:.2s] before:absolute before:left-0 before:top-0 before:size-full before:bg-[radial-gradient(circle,theme(colors.good),transparent_var(--rate))] before:blur-lg [&_>_*]:relative"
      theme="BRAND">
      <p className="figure">{positiveInteractionRate}%</p>
      <p className="mt-2 label">
        <EvaluationPicto evaluation="GOOD" className="mr-2" />
        Interactions positives
      </p>
    </BentoLayout.HalfRowCard>
  </BentoLayout.RowOfCards>
);

const FeedbackGlobal = ({
  className = '',
  unlockedTrophyID,
  statsFeedbacks,
  improvementAxes,
  onClickOnShowDetailLink
}: {
  className?: string;
  unlockedTrophyID: TrophyIdType;
  statsFeedbacks?: StatsFeedbackModel;
  improvementAxes?: ImprovementAxesModel[];
  onClickOnShowDetailLink?: () => void;
}) => {
  const [isTrophyExplanationsModalOpened, setIsTrophyExplanationsModalOpened]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const toggleModal = () => {
    setIsTrophyExplanationsModalOpened(!isTrophyExplanationsModalOpened);
  };

  const userTrophy: TrophyModel = TROPHIES[unlockedTrophyID];

  return (
    <>
      <BentoLayout className={`${className} flex-1 sm:px-3`}>
        <BentoLayout.Column>
          <BentoLayout.FullRowCard
            dataTestid={`trophy-${userTrophy.id}`}
            className={` ${
              userTrophy.id === 'LIMIT_CASE' ? 'bg-gray' : ''
            } relative flex flex-1 flex-col justify-center overflow-hidden text-center [&_>_*]:relative`}
            theme="BRAND">
            <div
              className={`relative before:absolute before:left-1/2 before:top-1/2 before:h-[1594px] before:w-[1776px] before:-translate-x-1/2 before:-translate-y-1/2 before:mask-brand-watermark before:[mask-position:calc(50%_+_540px)_calc(50%_-_450px)] before:[mask-repeat:no-repeat] [&_>_*]:relative ${
                userTrophy.id === 'LIMIT_CASE' ? 'before:bg-white/10' : 'before:bg-accent'
              }`}>
              {userTrophy.intro}
            </div>
            <h1 className="mt-4 title-lg">{userTrophy.message}</h1>
            <p className="mt-4">
              <button className="cta" onClick={() => toggleModal()}>
                En savoir plus
              </button>
            </p>
          </BentoLayout.FullRowCard>

          {!userTrophy.isLastUnlockableTrophy && statsFeedbacks && (
            <TrophyStatsRow
              diffApproachUserRate={DIFF_APPROACH_USER_RATE}
              positiveInteractionRate={statsFeedbacks.PositiveInteractionsInPercent}
            />
          )}
        </BentoLayout.Column>
        <BentoLayout.Column>
          {userTrophy.isLastUnlockableTrophy && statsFeedbacks && (
            <TrophyStatsRow
              diffApproachUserRate={DIFF_APPROACH_USER_RATE}
              positiveInteractionRate={statsFeedbacks.PositiveInteractionsInPercent}
            />
          )}

          <BentoLayout.RowOfCards>
            {!userTrophy.isLastUnlockableTrophy && (
              <BentoLayout.HalfRowCard className="not-important-opacity-0 flex animate-fadeIn flex-col justify-between [animation-delay:.3s]">
                <h2 className="flex-1 label">Axes d’amélioration</h2>
                <ul className="list-none text-sm font-light [&_li+li]:mt-2">
                  {improvementAxes?.map((item: ImprovementAxesModel) => (
                    <li
                      key={item.index}
                      className={item.kind === 'WARNING' ? 'marker-warning' : 'marker-tips'}>
                      {item.label}
                    </li>
                  ))}
                </ul>
              </BentoLayout.HalfRowCard>
            )}

            {TROPHY_GLOBAL_USER_RATE && (
              <BentoLayout.HalfRowCard className="not-important-opacity-0 flex animate-fadeIn flex-col justify-between [animation-delay:.4s]">
                <header className="flex w-full">
                  <h2 className="flex-1 label">
                    Pourcentage d’apprenants ayant atteint le niveau {userTrophy.name}
                  </h2>
                  <svg
                    width="29"
                    height="32"
                    viewBox="0 0 29 32"
                    className="ml-4 h-8 w-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false">
                    <use href={`${defs}#histogram`} />
                  </svg>
                </header>
                <p className="mb-4 figure">{TROPHY_GLOBAL_USER_RATE}%</p>
              </BentoLayout.HalfRowCard>
            )}
          </BentoLayout.RowOfCards>

          <BentoLayout.FullRowCard
            dataTestid={`trophy-next-${userTrophy.id}`}
            className="not-important-opacity-0 relative flex flex-1 animate-fadeIn flex-col overflow-hidden [animation-delay:.5s] before:absolute before:inset-0 before:bg-brand-watermark before:bg-[length:400px_auto] before:bg-[position:calc(100%_+_50px)_-40px] before:bg-no-repeat [&_>_*]:relative">
            <h2 className="mt-auto title [&_strong]:text-accent">{userTrophy.ctaSection.title}</h2>
            <p className="mt-4 text-sm font-semibold">{userTrophy.ctaSection.text}</p>

            <p className="-mx-3 -mb-3 mt-3 flex flex-wrap">
              <a
                href="#detail"
                data-testId="feedback-show-detail-button"
                onClick={(event: React.MouseEvent) => {
                  if (onClickOnShowDetailLink) {
                    event.preventDefault();
                    onClickOnShowDetailLink();
                  }
                }}
                className={`cta m-3 ${
                  userTrophy.isLastUnlockableTrophy ? 'cta--white' : 'cta--accent'
                }`}>
                Voir le détail
              </a>
              {userTrophy.id !== 'LIMIT_CASE' && (
                <a
                  href="/"
                  className={`cta m-3 ${
                    userTrophy.isLastUnlockableTrophy ? 'cta--accent' : 'cta--white'
                  }`}>
                  Conclure la formation
                </a>
              )}
            </p>
          </BentoLayout.FullRowCard>
        </BentoLayout.Column>
      </BentoLayout>

      {isTrophyExplanationsModalOpened && (
        <Modal onClose={() => toggleModal()}>
          <Modal.Header>
            <Modal.Title>
              <Translation keyName="trophies.modal.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Content>
            <Translation keyName="trophies.modal.explanations" />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default FeedbackGlobal;
