import log from 'loglevel';
import MediaDevices from './MediaDevices/Manager';
import RecorderSession from './Recorder/Session';
import SpeechToTextManager from './SpeechToTextManager';

export default class Manager {
  constructor(sdk) {
    this._sdk = sdk;
    this._mediaDevice = new MediaDevices({
      onDeviceUpdate: (data) => {
        log.debug('VideoConf Manager constructor: new MediaDevices > onDeviceUpdate. data ', data);
        this._sdk.event().emit('mediaDeviceUpdated', data);
      },
      onReady: () => {
        log.debug(
          'VideoConf Manager constructor: new MediaDevices > onReady. AudioManager ',
          this._mediaDevice.getAudioManager()
        );
        this._mediaDevice.getAudioManager().setBinaryDataProcessing('ScriptProcessor');

        this._mediaDevice.getAudioManager().on('volume', (data) => {
          this._sdk.event().emit('volume', data);
        });
      }
    });
    this._speechToTextManager = new SpeechToTextManager(sdk);
  }

  mediaDevices() {
    return this._mediaDevice;
  }

  async startRecorderSession() {
    this._recorder = new RecorderSession(this._sdk.socket(), this._mediaDevice.getStream(), {
      encoding: {
        videoBitsPerSecond: 800000
      },
      onReady: () => {
        log.debug('on ready');
        this._recorder.start();
      }
    });
  }

  toggleWebcamHide() {
    this._webcamHide
      ? this._mediaDevice.resumeTrack('video')
      : this._mediaDevice.pauseTrack('video');

    this._webcamHide = !this._webcamHide;
    this._sdk.event().emit('toggleWebcam', this._webcamHide);
  }

  isWebcamHide() {
    return this._webcamHide;
  }

  async stopRecorderSession() {
    this._recorder.stop();
  }

  getRecorderState() {
    return this._recorder.getState();
  }

  async checkIsFakeAudioSession() {
    if (!window.testMode.fakeUserAudioToPlay) {
      return false;
    }

    log.debug('WE ARE IN FAKE SESSION');
    let { mediaStream, sourceNode } = await this._mediaDevice
      .getAudioManager()
      .loadAudioFile(window.testMode.fakeUserAudioToPlay);
    this._mediaDevice.getAudioManager().setStream(mediaStream);
    this.fakePlayer = sourceNode;
    return true;
  }

  async createTranscriptionSession(onPartialTranscriptedTextCallback, onTranscriptedTextCallback) {
    log.debug(new Date().toUTCString() + ' - Manager createTranscriptionSession');

    const isFakeSession = await this.checkIsFakeAudioSession();

    if (isFakeSession) {
      return {
        start: () =>
          this._speechToTextManager.handleFakeSession(
            onPartialTranscriptedTextCallback,
            onTranscriptedTextCallback
          ),
        close: () => {}
      };
    } else {
      return this._speechToTextManager.createTranscriptionSession(
        onPartialTranscriptedTextCallback,
        onTranscriptedTextCallback
      );
    }
  }

  async refreshSpeechTokenIfNeeded() {
    return this._speechToTextManager.refreshTokenIfNeeded();
  }

  getSpeechToTextManager() {
    return this._speechToTextManager;
  }
}
