import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { PasswordValidationIndicator } from '@/components/Utilities/PasswordValidationIndicator';
import { Translation } from '@/components/Utilities/Translation';

export function withRouter(Children) {
  return (props) => {
    const navigate = useNavigate();
    return <Children {...props} navigate={navigate} />;
  };
}

class Register extends React.Component {
  state = {
    email: '',
    password: '',
    password2: '',
    firstName: '',
    lastName: '',
    showNameFields: false,
    entity: '',
    failed: false,
    successed: false,
    info: {},
    visible: false,
    privacy: false,
    valided: {
      number: false,
      special: false,
      length: false,
      min: false,
      maj: false,
      email: true,
      password: true,
      password2: true,
      entity: true,
      privacy: true
    }
  };

  // Test si email valide
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Envoie le formulaire
  check = async () => {
    const { navigate } = this.props;

    const { email, valided } = this.state;
    if (!email || !valided.email) {
      valided.email = false;
      this.setState({
        valided: valided
      });
      return false;
    }

    let res;

    try {
      res = await window.sdk.user().checkEmail(this.state.email);
    } catch (e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion' //@TODO: translate me
        }
      });
      window.sdk.event().emit('loaderHide');
      return;
    }

    if (res && res.isSelfSignUpEnabled) {
      this.setState({
        showNameFields: true,
        isPending: true
      });
      return;
    }

    if (res && res.isActive) {
      navigate('/login', {
        state: { email: this.state.email, redirect_url: window.sdk.getParam('redirect_url') }
      });
    }

    if (res && res.isPending) {
      this.setState({
        isPending: true
      });
      return;
    }

    this.setState({
      failed: true,
      info: {
        message: 'Erreur connexion' //@TODO: translate me
      }
    });
  };

  submit = async () => {
    const { password, password2, privacy, valided } = this.state;
    if (!privacy || !valided.privacy) return false;
    if (!password || !valided.number || !valided.special || !valided.min || !valided.maj) {
      valided.password = false;
      this.setState({
        valided: valided
      });
      return false;
    }
    if (!password2 || password2 !== password) {
      return false;
    }

    let res;
    try {
      if (this.state.showNameFields) {
        res = await window.sdk.user().register({
          email: this.state.email,
          password: this.state.password,
          firstName: this.state.firstName,
          lastName: this.state.lastName
        });
      } else
        res = await window.sdk.user().register({
          email: this.state.email,
          password: this.state.password
        });
    } catch (e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion'
        }
      });
      window.sdk.event().emit('loaderHide');
    }

    if (res && res.state === 'fail') {
      this.setState({
        failed: true,
        info: res.info
      });
    } else {
      try {
        res = await window.sdk.user().login({
          username: this.state.email,
          password: this.state.password,
          newpassword: this.state.password,
          name: this.state.email
        });
      } catch (e) {
        this.setState({
          failed: true,
          info: {
            message: 'Erreur connexion'
          }
        });
        window.sdk.event().emit('loaderHide');
      }

      if (res && res.state === 'fail') {
        this.setState({
          failed: true,
          info: res.info
        });
      }
    }
  };

  updateEmail = (value) => {
    const { valided } = this.state;
    valided.email = value.length === 0 ? true : this.validateEmail(value);
    this.setState({
      email: value,
      failed: false,
      valided: valided
    });
  };

  updatePrivacy(value) {
    const { valided } = this.state;
    valided.privacy = value;

    this.setState({
      privacy: value,
      failed: false,
      valided: valided
    });
  }

  updateField = (key, value) => {
    this.setState({ [key]: value });
    this.forceUpdate();
  };

  updatePassword = (value) => {
    const { valided } = this.state;
    valided.password = true;
    valided.number = value.search(/.*\d/) >= 0;
    valided.special = /[^a-zA-Z0-9]/.test(value);
    valided.length = value.length >= 8;
    valided.min = value.search(/.*[a-z]/) >= 0;
    valided.maj = value.search(/.*[A-Z]/) >= 0;
    this.setState({
      password: value,
      failed: false,
      valided: valided
    });
  };

  updatePasswordConfirm = (value) => {
    const { valided } = this.state;
    valided.password2 = true;
    if (value !== this.state.password) valided.password2 = false;
    this.setState({
      password2: value,
      failed: false,
      valided: valided
    });
  };

  clearEmail = () => {
    this.setState({
      isPending: false,
      email: ''
    });
  };

  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };
  togglePassword2 = () => {
    this.setState({
      showPassword2: !this.state.showPassword2
    });
  };

  render() {
    const { valided, showPassword, showPassword2, showNameFields } = this.state;
    return (
      <>
        <h2 className="title">
          {this.state.isPending ? (
            <>Veuillez saisir les informations nécessaires à la création du&nbsp;compte</>
          ) : (
            <>Renseigner votre adresse email pour créer un compte ou vous&nbsp;connecter</>
          )}
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          {this.state.isPending ? (
            <>
              <Form.Group className="mt-4" controlId="email">
                <Form.Label className="pl-4 label">Email</Form.Label>
                <p className="flex items-center justify-between pl-4">
                  <i>{this.state.email}</i>

                  <button type="button" className="link text-xs" onClick={this.clearEmail}>
                    <Translation keyName="general.edit">Modifier</Translation>
                  </button>
                </p>
              </Form.Group>
              {showNameFields && (
                <>
                  <Form.Group className="mt-4" controlId="firstName">
                    <Form.Label className="pl-4 label">Prénom</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Votre prénom"
                      onChange={(e) => this.updateField('firstName', e.target.value)}
                      value={this.state.firstName}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mt-4" controlId="lastName">
                    <Form.Label className="pl-4 label">Nom</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Votre nom"
                      onChange={(e) => this.updateField('lastName', e.target.value)}
                      value={this.state.lastName}
                      required
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group className="mt-4" controlId="password">
                <Form.Label className="pl-4 label">Mot de passe</Form.Label>
                <div className="relative [&_.form-control]:pr-10">
                  <Form.Control
                    data-testid="register-password-input"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Votre mot de passe"
                    onChange={(e) => this.updatePassword(e.target.value)}
                    value={this.state.password}
                    required
                    isInvalid={!valided.password}
                  />
                  <button
                    type="button"
                    className="absolute right-4 top-1/2 -translate-y-1/2"
                    onClick={this.togglePassword}>
                    <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
                <PasswordValidationIndicator className="mt-3" valided={valided} />
              </Form.Group>
              <Form.Group className="mt-4" controlId="confirmPassword">
                <Form.Label className="pl-4 label">Confirmer votre mot de passe</Form.Label>
                <div className="relative [&_.form-control]:pr-10">
                  <Form.Control
                    type={showPassword2 ? 'text' : 'password'}
                    name="password2"
                    placeholder="Confirmez votre mot de passe"
                    onChange={(e) => this.updatePasswordConfirm(e.target.value)}
                    value={this.state.password2}
                    required
                    isInvalid={!valided.password2}
                  />
                  <button
                    type="button"
                    className="absolute right-4 top-1/2 -translate-y-1/2"
                    onClick={this.togglePassword2}>
                    <FontAwesomeIcon icon={!showPassword2 ? faEye : faEyeSlash} />
                  </button>
                </div>
              </Form.Group>

              <Form.Group className="mt-4" controlId="privacy">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    name="privacy"
                    checked={this.state.privacy}
                    onChange={(e) => this.updatePrivacy(e.target.checked)}
                    value={true}
                    required
                    isInvalid={!valided.privacy}
                  />
                  <Form.Check.Label className="cursor-pointer transition-colors">
                    J'ai lu et j'accepte{' '}
                    <a
                      href={window.policyUri}
                      className="link text-current hover:text-current"
                      target="_blank"
                      rel="noreferrer">
                      la politique de&nbsp;confidentialité
                    </a>
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </>
          ) : (
            <Form.Group className="mt-4" controlId="email">
              <Form.Label className="pl-4 label">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Votre adresse email"
                onChange={(e) => this.updateEmail(e.target.value)}
                value={this.state.uname}
                required
                isInvalid={!valided.email}
                data-testid="register-email-input"
              />
            </Form.Group>
          )}

          {this.state.failed && (
            <Alert className="alert mt-4" data-testid="register-error-message" variant="danger">
              {this.state.info.message === 'not found'
                ? 'Désolé, vous ne semblez pas être dans la liste des emails autorisés'
                : this.state.info.message}
            </Alert>
          )}

          <button
            className="cta cta--accent mt-4"
            data-testid="register-submit-btn"
            variant="primary"
            disabled={this.props.loading}
            type="submit"
            onClick={this.state.isPending ? this.submit : this.check}>
            Valider
          </button>
        </form>
      </>
    );
  }
}

// Wrap and export
export default withRouter(Register);
