import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import ValueString from './ValueString';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';
import Utils from '../../Utils/Utils';
import FakeUser from '../../Participants/FakeUser';

export default class SpeechToText extends ExerciseNode {
  // References
  transcriptionSession;

  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);
  FirstWord = new NodePort('FirstWord', 'output', this); // Connected to nodes waiting for first words event
  Result = new NodePort('Result', 'output', this); // Connected to string value nodes that store or use the string result of the speech recognition
  Failed = new NodePort('Failed', 'output', this); // If an error or timeout occurs

  // Parameters
  Endpoint = '';
  EndSilenceSeconds = 2;
  PhraseList = [];

  // Internal values
  m_Engine = 'MicrosoftCognitiveServicesSpeech';
  m_StartTime = null;
  m_PartialSpeechDetected = '';
  m_SpeechDetected = '';
  m_LastSpeechDate = null;
  m_TranscriptionID = '';
  m_STTDataID = '';
  m_ParentNode = null;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.Endpoint = iProperties.Endpoint;
    this.EndSilenceSeconds = iProperties.EndSilenceSeconds;
    this.PhraseList = iProperties.PhraseList;
    // For smart branching decision inclusion, we need to know the parent node
    this.m_ParentNode = iProperties.ParentNode;

    this.m_InitialSilenceTimeout = 10000; // 10 second initial silence timeout
    this.m_InitialSilenceTimer = null;

    /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
        + ", id = " + this.ID 
        + ", Endpoint = " + this.Endpoint 
        + ", End Silence = " + this.EndSilenceSeconds 
        + ", PhraseList = " + this.PhraseList); */
  }

  async OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    // Rewind mode
    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

    // Initializations
    this.m_SpeechDetected = '';
    this.m_PartialSpeechDetected = '';
    this.m_StartTime = new Date();
    this.m_LastSpeechDate = new Date();
    this.m_TranscriptionID = Utils.CreateObjectIDWithIncrement(
      this.m_StartTime,
      window.sdk.user().userID,
      this.m_Engine
    );

    // Set up initial silence timer
    this.m_InitialSilenceTimer = setTimeout(() => {
      if (!this.m_PartialSpeechDetected) {
        this.OnFailed('Initial silence timeout reached');
      }
    }, this.m_InitialSilenceTimeout);

    // Test mode - force fake user speech: will force the speech to text result and skip the actual speech recognition
    if (window.testMode.fakeUserMode) {
      const fakeSpeech = await FakeUser.GetFakeUserSpeech(
        this.Graph.History.GetConversationAsText()
      );
      this.OnPartialSpeechDetected(fakeSpeech);
      this.OnSpeechDetected(fakeSpeech);
      if (window.testMode.autoSkip) {
        setTimeout(() => {
          window.sdk.event().emit('skipExerciseStep');
        }, 1000);
      }
      return;
    }
    // Test mode - force fake user speech: will force the speech to text result and skip the actual speech recognition
    if (window.testMode.fakeUserSpeechToForce) {
      this.OnPartialSpeechDetected(window.testMode.fakeUserSpeechToForce);
      this.OnSpeechDetected(window.testMode.fakeUserSpeechToForce);
      if (window.testMode.autoSkip) {
        setTimeout(() => {
          window.sdk.event().emit('skipExerciseStep');
        }, 1000);
      }
      window.testMode.fakeUserSpeechToForce = '';
      return;
    }
    // Test mode - force fake user actions: will force a dummy user speech and skip the actual speech recognition
    else if (window.testMode.forceUserActionsMode) {
      this.OnPartialSpeechDetected('Fake user speech to force user actions.');
      this.OnSpeechDetected('Fake user speech to force user actions.');
      return;
    }

    // Start speech recognition
    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('readyforSpeaking');

    // Setup the phrase list
    let phraseListToUse = [];
    if (this.PhraseList && this.PhraseList.length) {
      // If PhraseList is provided, use it
      phraseListToUse = this.PhraseList;
    } else if (this.Graph.ExerciseSettings.STTPhraseList && this.Graph.ExerciseSettings.STTPhraseList.length) {
      // If PhraseList is not provided, use STTPhraseList from ExerciseSettings
      phraseListToUse = this.Graph.ExerciseSettings.STTPhraseList;
    }
    // If both are empty, phraseListToUse will remain an empty array

    this.transcriptionSession = await window.sdk
      .videoconf()
      .getSpeechToTextManager()
      .createTranscriptionSession(
        (text, dataID) => {
          this.OnPartialSpeechDetected(text);
        },
        (text, dataID) => {
          this.OnSpeechDetected(text, dataID);
        },
        false, // isMicValidation
        phraseListToUse // Pass the phrase list to the transcription session
      );
    this.transcriptionSession.start();
  }

  // Triggered when the STT finds the first words
  OnFirstWordDetected() {
    // Clear the initial silence timer
    if (this.m_InitialSilenceTimer) {
      clearTimeout(this.m_InitialSilenceTimer);
      this.m_InitialSilenceTimer = null;
    }

    // If inactive, ignore
    if (!this.m_IsActive) {
      log.debug(this.GetIdentity() + ' OnFirstWordDetected: ignored because node is inactive!');
      return;
    }

    // If paused, ignore
    if (this.IsPaused()) {
      log.debug(this.GetIdentity() + ' OnFirstWordDetected: ignored because node is paused!');
      return;
    }

    // Show "Vous parlez" state on the user's video slot
    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('speaking');

    log.debug(this.GetIdentity() + ' OnFirstWordDetected.');

    this.FirstWord.ActivateAllConnections();

    // If this node is a child of a smart branching decision, we need to send the first word to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnFirstWordDetected();
    }
  }

  // Triggered when the STT finished the speech recognition (EndSilence timeout)
  OnPartialSpeechDetected(iResult) {
    // If inactive, ignore
    if (!this.m_IsActive) {
      log.debug(
        this.GetIdentity() +
          " OnPartialSpeechDetected: '" +
          iResult +
          "' ignored because node is inactive!"
      );
      return;
    }

    log.debug(this.GetIdentity() + " OnPartialSpeechDetected: '" + iResult + "'.");

    this.StartWaitingSpeechEnd();

    // If these are the first words, trigger the first word detection event
    if (this.m_PartialSpeechDetected === '') {
      this.OnFirstWordDetected();
    }

    this.m_LastSpeechDate = new Date();
    this.m_PartialSpeechDetected = iResult;

    // Send the string result to the Exercise React component
    window.sdk.event().emit('setSpeechFeedbackText', this.m_PartialSpeechDetected);

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnPartialSpeechDetected(iResult);
    }
  }

  // Triggered when the STT finished the speech recognition (EndSilence timeout)
  OnSpeechDetected(iResult, iSTTDataID = '') {
    // If inactive, ignore
    if (!this.m_IsActive) {
      log.debug(
        this.GetIdentity() +
          " OnSpeechDetected: '" +
          iResult +
          "' ignored because node is inactive!"
      );
      return;
    }

    // If paused, ignore
    if (this.IsPaused()) {
      log.debug(
        this.GetIdentity() + " OnSpeechDetected: '" + iResult + "' ignored because node is paused!"
      );
      return;
    }

    log.debug(this.GetIdentity() + " OnSpeechDetected: '" + iResult + "'.");

    this.m_STTDataID = iSTTDataID;
    this.m_SpeechDetected += iResult + ' ';

    this.StartWaitingSpeechEnd();

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSpeechSegmentation(this.m_SpeechDetected);
    }
  }

  StartWaitingSpeechEnd() {
    // Cancel the previous timeout if it exists
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    // Start a new timer
    this.currentTimeout = setTimeout(() => {
      this.OnUserFinishedTalking();
    }, 2000); // TODO: Use this.EndSilenceSeconds * 1000 for the real delay, but needs to be set at 2s in the graph first
  }

  OnUserFinishedTalking() {
    log.debug(this.GetIdentity() + ' OnUserFinishedTalking: user stopped talking.');

    const currentDate = new Date();

    // Log to test variables
    if (window.testMode.fillAppStateValues) {
      window.testMode.appStateValues['lastSpeechToText'] = this.m_SpeechDetected;
    }

    // Log action to history
    const historyEventID = this.Graph.History.AddUserSpeech(
      this.ID,
      '', // Will be set later, when the analysis task is created
      this.m_SpeechDetected,
      currentDate,
      this.Graph.LastBranchingDecisionNode.DatabaseID
    );

    // Log to DynamoDB
    window.sdk
      .AnalysisTask()
      .createOne(
        this.Graph.LastBranchingDecisionNode.DatabaseID
          ? this.Graph.LastBranchingDecisionNode.DatabaseID
          : 'undefined', // Parent Branching Decision Node
        this.ID.toString(), // Node ID
        this.m_Engine, // analyzer Engine
        '1', // Analyzer Version
        'raw', // Analysis Status
        this.m_TranscriptionID, // Analysis Input
        this.m_StartTime, // Start Time
        (currentDate.getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
        '', // Possible choices
        { 
          transcript: this.m_SpeechDetected, 
          STTDataID: this.m_STTDataID,
          language: window.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR'
        }, // Analysis Result
        this.Graph.ExerciseID.toString() // Exercise ID
      )
      .then((analysisTask) => {
        // Update action in history
        this.Graph.History.UpdateUserSpeech(
          historyEventID,
          this.ID,
          analysisTask.ID,
          this.m_SpeechDetected,
          this.Graph.LastBranchingDecisionNode.DatabaseID
        );

        return analysisTask;
      })
      .then(async (analysisTask) => {
        let beautifiedSpeech = '';

        if (window.testMode.forceUserActionsMode) {
          beautifiedSpeech = 'Fake user speech to force user actions.';
        } else {
          beautifiedSpeech = (await this.BeautifyUserSpeech(this.m_SpeechDetected)) || '';
        }

        this.Graph.History.UpdateUserSpeech(
          historyEventID,
          this.ID,
          analysisTask.ID,
          this.m_SpeechDetected,
          this.Graph.LastBranchingDecisionNode.DatabaseID,
          beautifiedSpeech
        );
      });

    // Send the string result to the debug info UI
    window.sdk.event().emit('setSpeechFeedbackText', this.m_SpeechDetected);
    this.OutputResult();

    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }

    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('no');

    // Reset
    this.m_PartialSpeechDetected = '';

    this.ActivateOutput();

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSpeechDetected(this.m_SpeechDetected);
    }
  }

  // Triggered when the STT finished the speech recognition (EndSilence timeout)
  OnFailed(iReason) {
    log.debug(this.GetIdentity() + " OnFailed: Reason '" + iReason + "'.");

    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }

    // Log to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.Graph.LastBranchingDecisionNode.DatabaseID
        ? this.Graph.LastBranchingDecisionNode.DatabaseID
        : 'undefined', // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      this.m_Engine, // analyzer Engine
      '1', // Analyzer Version
      'failed', // Analysis Status
      this.m_TranscriptionID, // Analysis Input
      this.m_StartTime, // Start Time
      (new Date().getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
      '', // Possible choices
      "Failed! Reason: '" +
        iReason +
        "'. Partial speech detected: '" +
        this.m_PartialSpeechDetected +
        "'.",
      this.Graph.ExerciseID.toString() // Exercise ID // Analysis Result
    );

    // this.Graph.ParentExerciseComponent.SetSpeechFeedbackText("Failed: " + iReason + ". Partial speech detected: '" + this.m_PartialSpeechDetected + "'.");
    window.sdk
      .event()
      .emit(
        'setSpeechFeedbackText',
        'Failed: ' + iReason + ". Partial speech detected: '" + this.m_PartialSpeechDetected + "'."
      );

    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('no');

    // Reset
    this.m_PartialSpeechDetected = '';
    this.SetActive(false);

    // Output to 'Failed' port
    this.Failed.ActivateAllConnections();

    // If this node is a child of a smart branching decision, send the failed event to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSTTFailed();
    }
  }

  // Send the string result to the nodes connected to the SpeechResult_OutputNodes port
  OutputResult() {
    log.debug(
      this.GetIdentity() +
        " OutputResult '" +
        this.m_SpeechDetected +
        "' to " +
        this.Result.GetConnectionsCount() +
        ' nodes: ' +
        this.Result.ListPortConnections()
    );

    this.Result.GetConnectedNodes().forEach((node) => {
      if (node instanceof ValueString) {
        node.SetValue(this.m_SpeechDetected);
      }
    });
  }

  Resume() {
    super.Resume();

    if (this.m_IsActive) {
      this.OnFailed('Node was paused while waiting for speech recognition.');
    }
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }

  GetStringValue() {
    return this.m_SpeechDetected;
  }

  OnDeactivated() {
    log.debug(
      this.GetIdentity() + " OnDeactivated: speech found = '" + this.m_SpeechDetected + "'."
    );

    // Clear the initial silence timer if it's still active
    if (this.m_InitialSilenceTimer) {
      clearTimeout(this.m_InitialSilenceTimer);
      this.m_InitialSilenceTimer = null;
    }

    // Stop current speech recognition if running
    // TODO: VoiceControlManager.Instance.CancelSpeechRecognition(this);
    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }

    super.OnDeactivated();
  }

  async BeautifyUserSpeech(iUserSpeech) {
    // @TODO: for now, for S2/S3/S4/Sn ... we only have one bot, so we can hardcode
    const botName = ParticipantsModule.Instance.GetAllBots()[0].Name;
    const botGender = ParticipantsModule.Instance.GetAllBots()[0].Gender || 'M';
    const contextualInfo = this.Graph.ExerciseSettings.PromptContextualInfo || '';

    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/beautify-user-speech', {
        body: {
          userSpeech: iUserSpeech,
          botName,
          botGender,
          contextualInfo,
          exerciseSessionID: this.Graph.CurrentExerciseSessionID
        }
      });

      return gptAnswer.beautifiedUserSpeech;
    } catch (error) {
      log.error(`SpeechToText.BeautifyUserSpeech: ${error}`);
    }

    return null;
  }

  PrintParameters() {
    //log.debug("SpeechToText: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", End Silence = " + this.EndSilence);
  }

  //////////////////////////
  // Test functions
  //////////////////////////

  TestExecute(iActivator, iInputPort, iTestReport) {
    // Initialize the test
    //this.m_ExecutionMode = "Test"; Not needed?
    this.m_SpeechDetected = iTestReport.UserSpeech;

    this.Output.TestActivateAllConnections(iTestReport);
  }
}
