import { useEffect, useState } from 'react';
import log from 'loglevel';
import { TrophyIdType } from '@/models/TrophyModel';
import { DetailedFeedbackModel } from '@/models/DetailedFeedbackModel';
import { StatsFeedbackModel } from '@/models/StatsFeedbackModel';

type ExerciseSessionEvent = {
  ExerciseSessionID: string;
  EventID: string;
  UserID: string;
  EventType: string;
  Date: Date;
  Content: any;
};

const UNLOCKED_TROPHY_EVENT_TYPE = 'UnlockedTrophy';
const DETAILED_FEEDBACKS_EVENT_TYPE = 'DetailedFeedbacks';
const STATS_FEEDBACKS_EVENT_TYPE = 'StatsFeedbacks';
const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // 2 seconds

export function useFeedback(exerciseSessionID: string | undefined) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [unlockedTrophyID, setUnlockedTrophyID] = useState<TrophyIdType>();
  const [detailedFeedbacks, setDetailedFeedback] = useState<DetailedFeedbackModel[]>();
  const [statsFeedbacks, setStatsFeedbacks] = useState<StatsFeedbackModel>();

  useEffect(() => {
    let retryCount = 0;
    let timeoutId: NodeJS.Timeout;

    const fetchFeedbacks = async () => {
      if (!exerciseSessionID) {
        setIsLoading(false);
        return;
      }

      try {
        const sessionHistory: ExerciseSessionEvent[] = await window.sdk
          .exerciseSession()
          .getExerciseSessionHistory(exerciseSessionID);

        const lastUnlockedTrophyEvent = getLastEventByType(
          sessionHistory,
          UNLOCKED_TROPHY_EVENT_TYPE
        );

        const lastStatsFeedbacksEvent = getLastEventByType(
          sessionHistory,
          STATS_FEEDBACKS_EVENT_TYPE
        );

        const lastDetailedFeedbacksEvent = getLastEventByType(
          sessionHistory,
          DETAILED_FEEDBACKS_EVENT_TYPE
        );

        const unlockedTrophyID = lastUnlockedTrophyEvent?.Content.UnlockedTrophyID;
        const statsFeedbacks = lastStatsFeedbacksEvent?.Content.StatsFeedbacks;
        const detailedFeedbacks = lastDetailedFeedbacksEvent?.Content.DetailedFeedbacks;

        if (!detailedFeedbacks && retryCount < MAX_RETRIES) {
          retryCount++;
          timeoutId = setTimeout(fetchFeedbacks, RETRY_DELAY);
          return;
        }

        setUnlockedTrophyID(unlockedTrophyID);
        setDetailedFeedback(detailedFeedbacks);
        setStatsFeedbacks(statsFeedbacks);
        setIsLoading(false);
      } catch (error: any) {
        log.error(error);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchFeedbacks();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [exerciseSessionID]);

  return { unlockedTrophyID, detailedFeedbacks, statsFeedbacks, isLoading, errorMessage };
}

function getLastEventByType(
  events: ExerciseSessionEvent[],
  eventType: string
): ExerciseSessionEvent | undefined {
  return events
    .filter((event) => event.EventType === eventType)
    .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime())[0];
}
