import React, { useContext } from 'react';
import { UserActionModel } from '@/models/DetailedFeedbackModel';
import { FeedbackTabsContext } from './FeedbackDetail';
import { UserActionsHeader } from './UserActionsHeader';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';

export const UserActions = ({
  userActions = [],
  onModalClick,
  className = ''
}: {
  userActions: UserActionModel[];
  onModalClick: (title: string, content: string) => void;
  className?: string;
}) => {
  const { activeUserActionId } = useContext(FeedbackTabsContext);

  return (
    <>
      <ul className={className}>
        {userActions.map((userAction: UserActionModel) => (
          <li
            key={userAction.id}
            id={'panel-' + userAction.id}
            role="tabpanel"
            aria-labelledby={`tab-${userAction.id}`}
            tabIndex={0}
            data-testid={`user-action-feedback-${userAction.id}`}
            {...(userAction.evaluation && {
              style: {
                '--evaluation': `var(--${userAction.evaluation.toLowerCase()}-color)`
              } as React.CSSProperties
            })}
            className={`${
              activeUserActionId !== userAction.id ? 'hidden' : 'animate-fadeIn'
            } [&_strong]:text-[var(--evaluation)]`}>
            {userActions.length > 1 ? (
              <UserActionsHeader userActions={userActions} className="my-6">
                {userAction.displayedName}
              </UserActionsHeader>
            ) : (
              <h3
                className={`mb-8 mt-6 text-center text-[var(--evaluation)] transition-colors label [&.label]:text-sm`}>
                {userAction.displayedName}
              </h3>
            )}

            <p className="label">{userAction.botSpeech.botName} vous dit</p>
            <p className="mt-2 marker-quote">{userAction.botSpeech.transcript}</p>
            <p className="mt-6 label">Votre réponse</p>
            <p className="mt-2 marker-quote">{userAction.userSpeech}</p>

            <p className="mt-4 text-center">
              <button
                className="cta"
                onClick={() => onModalClick(userAction.displayedName, userAction.detailsText)}>
                En savoir plus
              </button>
            </p>
          </li>
        ))}
      </ul>
    </>
  );
};
